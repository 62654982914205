import React, { useEffect, useState } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useQueryParam, StringParam } from 'use-query-params'
import { authorizeConfirmApproval } from 'scripts/authorizeConfirmApproval'
import { cookies } from 'scripts/cookies'
import { Main } from './Main'

export const VerifySecretQuestionSuccess = () => {
  const [lockedDeviceUuid] = useState(cookies.get('_locked_device_uuid'))
  const [lockedEventId] = useState(cookies.get('_locked_event_id'))
  const [isSaml] = useQueryParam('saml', StringParam)
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)

  useEffect(() => {
    const handle = window.setInterval(() => {
      ;(async () => {
        const params = {
          locked_event_id: lockedEventId,
          locked_device_uuid: lockedDeviceUuid,
          is_saml: isSaml === 'true',
        }
        await authorizeConfirmApproval({
          handle,
          params: { ...params, secret_question_answered: 'answered' },
          appClientKey: appClientKeyQueryParam,
        })
      })()
    }, 5000)
    setTimeout(function () {
      clearInterval(handle)
    }, 300000) // 5分で解除
    return () => clearInterval(handle)
  }, [])

  return (
    <Layout dataTest="loadedLayout">
      <Main
        isSaml={isSaml}
        lockedDeviceUuid={lockedDeviceUuid}
        lockedEventId={lockedEventId}
        appClientKey={appClientKeyQueryParam}
      />
    </Layout>
  )
}
