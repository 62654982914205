// 真似した
// https://github.com/evandromacedo/react-simple-snackbar
import { useSnackbarContext } from './index'

export const useSnackbar = () => {
  // https://stackoverflow.com/questions/42273853/in-typescript-what-is-the-exclamation-mark-bang-operator-when-dereferenci
  const { openSnackbar, closeSnackbar } = useSnackbarContext()!

  function open(text: string | string[] = '', dataTest = 'snackbar') {
    openSnackbar(text, dataTest)
  }

  return [open, closeSnackbar]
}
